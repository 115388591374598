<template>
    <div class="hypo-form-group">
        <label 
            :for="selectName" 
            :class="labelClass">
            {{ label }} <img v-if="labelTooltip" 
                                class="label-help" 
                                src="@/assets/icons/help.svg" 
                                v-bPopover="labelTooltip" />
        </label>
        
        <v-select-list class="hypo-select"
                       :selectName="selectName"
                       :items="items"
                       v-model="localValue"
                       @update:modelValue="onInput"
                       :addonString="addonString"
                       :multiselect="multiselect"
                       :data-toggle="inputTooltip ? 'tooltip' : null" :title="inputTooltip" data-placement="auto" data-container="body"
                       >
        </v-select-list>
    </div>
</template>

<script>
    import SelectList from './SelectList.vue'

    export default {
        name: 'v-labeled-select-list',
        data() {
            return {
                localValue: this.modelValue,
            }
        },
        components: {
            'v-select-list' : SelectList
        },
        watch: {
            modelValue() {
                this.localValue = this.modelValue;
            }
        },
        methods: {
            onInput(val) {
                this.$emit('update:modelValue', val);
            }
        },
        emits: ['update:modelValue'],
        props: {
            selectName: {
                type: String,
                required: true
            },
            modelValue: String,
            items: Array,
            label: String,
            addonString: String,
            multiselect: Boolean,
            inputSize: {
                type: Number,
                default: 60
            },
            labelTooltip: String,
            inputTooltip: String,
            labelClass: String
        }
    }
</script>

<style scoped>
    .hypo-form-group {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }
    .hypo-form-group > label {
        color: #333;
        font-weight: normal;
        padding-bottom: 4px
    }

    img.label-help {
        filter: brightness(0) saturate(100%) invert(15%) sepia(0%) saturate(0%) hue-rotate(243deg) brightness(99%) contrast(86%);
    }

    img.label-help:hover {
        filter: brightness(0) saturate(100%) invert(50%) sepia(8%) saturate(7287%) hue-rotate(186deg) brightness(81%) contrast(102%);
    }
</style>